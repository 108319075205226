@import "./reset.css";

@import "./variables";
@import "./root";

.container {
  max-width: 1240px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

body {
  font-size: 16px;
  font-family: "PT Sans", sans-serif;
  line-height: 1.42857143;

  background-color: var(--color-white);
  color: var(--color-dark);

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > div:first-child,
div#__next,
div#__next > div {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section {
  padding: 30px 0;

  &__title {
    margin-bottom: 10px;
  }
  &__text {
    margin-bottom: 20px;
  }
  &-label {
    position: relative;
    &:before {
      content: attr(data-label);
      position: absolute;
      padding-bottom: 12px;
      top: -26px;

      left: 0;

      font-size: 12px;
      letter-spacing: 2px;
      font-weight: bold;
      font-family: "Proxima Nova";
      color: var(--color-blue);

      text-transform: uppercase;
    }
    &--center {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 150px;
    }
  }
}

.main {
  position: relative;
}

.main-padding-block {
  padding: 50px 20px;
}

.main-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 31.2px;
}

.title,
.subtitle {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}

.title-grey {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-grey);
}

.title-grey-thin {
  font-size: 16px;
  line-height: 21px;
  color: var(--color-grey);
}

.title-bold {
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
}

.light-blue {
  background-color: var(--color-light-blue);
}

a {
  // display: inline-block;
  text-decoration: none;
}
a img {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

a {
  color: var(--color-dark);
}

.email {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: var(--color-blue);
}

.phone {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: var(--color-dark);
}

#root {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.f {
  display: flex;
}
.jcc {
  justify-content: center;
}
.aic {
  align-items: center;
}

.highlight {
  border: 2px solid var(--color-orange);
  border-radius: 25px;
  padding: 20px;
}

.highlight > ul {
  margin-top: 10px;
}

.primary-button {
  background-color: var(--color-blue);

  color: var(--color-white);
  box-shadow: 0px 10px 25px rgba(4, 150, 255, 0.3);
  &:hover,
  &:active {
    box-shadow: 0px 0px 25px rgba(55, 87, 124, 0.1);
  }
  &--active {
    box-shadow: 0px 0px 25px rgba(55, 87, 124, 0.1);
  }
}

.simple-button {
  background-color: var(--color-white);
  box-shadow: 0px 0px 25px rgba(55, 87, 124, 0.1);
  color: var(--color-dark);

  &--active {
    box-shadow: 0px 10px 25px rgba(4, 150, 255, 0.3);
  }
}

.regular-button {
  background-color: var(--color-white);
  box-shadow: 0px 0px 25px rgba(55, 87, 124, 0.1);
  color: var(--color-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 30px;
  border-radius: 25px;
  font-size: 16px;
  line-height: 27px;
  transition: all 0.3s ease-in-out;

  max-width: 300px;
  &:hover,
  &:active {
    box-shadow: 0px 0px 0px rgba(55, 87, 124, 0.1);
    background-color: var(--color-light-blue);
    color: var(--color-dark);
  }
  &--active {
    background-color: var(--color-blue);
    color: var(--color-white);
    box-shadow: 0px 10px 25px rgba(4, 150, 255, 0.3);
  }
}

.primary-button,
.simple-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 28px;
  border-radius: 25px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  transition: all 0.3s ease-in-out;

  max-width: 300px;

  &:hover,
  &:active {
    background-color: var(--color-orange);
    color: var(--color-white);
  }
  &--active {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
}

.button-block {
  // background-color: var(--color-light-white);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &__item {
    & + & {
      margin-top: 10px;
    }
  }
}

.attention-label {
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  font-family: "Proxima Nova";
  color: var(--color-blue);
  margin-bottom: 5px;
  text-transform: uppercase;
}

@media only screen and (min-width: 360px) {
  .button-block {
    flex-direction: row;
    &__item {
      & + & {
        margin-left: 15px;
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .main-title {
    font-size: 40px;
    line-height: 52px;
  }
  .button-block {
    margin-bottom: 40px;
    &__item {
      & + & {
        margin-left: 30px;
      }
    }
  }
  .attention-label {
    font-size: 15px;
  }
  .title {
    font-size: 18px;
    line-height: 31px;
  }
  .title-bold {
    font-size: 18px;
    line-height: 31px;
  }
  .highlight {
    padding: 20px 30px;
  }
  .section {
    &__title {
      margin-bottom: 20px;
    }
    &__text {
      margin-bottom: 40px;
    }
    &-label {
      &:before {
        font-size: 15px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .button-block {
    margin-bottom: 50px;
  }

  .attention-label {
    writing-mode: vertical-rl;

    transform: rotate(180deg);
    padding-bottom: 15px;

    margin-bottom: 0;
  }
  .section {
    &-content {
      padding-left: 75px;
    }
    &__title {
      margin-bottom: 30px;
    }
    &__text {
      padding-right: 150px;
      margin-bottom: 50px;
    }
    &-label {
      &:before {
        min-height: 100%;

        top: 50%;
        writing-mode: vertical-rl;
        transform: translateY(-50%) rotate(180deg);

        left: -75px;

        text-align: center;
      }
    }
  }
}
