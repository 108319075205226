:root {
  --color-light-white: #f4faff;
  --color-white: #ffffff;
  --color-dark: #2a2d34;
  --color-blue: #0496ff;
  --color-blue-rgb: 4, 150, 255;
  --color-light-blue: #e0f2ff;
  --color-orange: #ffbd13;
  --color-grey: #b7bdc2;
  --color-red: #d22124;
  --color-orange-rgb: 255, 189, 19;
}
